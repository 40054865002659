
import "./App.css"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "../src/Components/navbar/navbar";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Banner from "./Components/banner/banner.js";
import Circle from "./Components/circle/circle.js";
import Roadmap from "./Components/roadmap/roadmap.js";
import Accomplishment from "./Components/accomplishment/accomplishment.js";
import Goals from "../src/Components/goals/goals.js";
import Links from "../src/Components/links/links.js";
import Footer from "../src/Components/footer/footer.js";
import StoryPage from "./Components/Pages/StoryPage.js";

function App() {

  const divStyle = {
    backgroundImage: 'url("/images/back.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '120vh',
    width: '100%',
  };
  return (
    <BrowserRouter>
      <div className="main-back">
        <div className="App" style={divStyle}>
          <div className="overlay">

            <Navbar />
            <Switch>
              <Route path="/storypage" render={() => <StoryPage />} />
              <Route path="/" render={() => <div>
                <Banner />
                <Accomplishment />
                <Goals />
                <Roadmap />
              </div>
              } />
            </Switch>
            <Links />
            <Footer />
          </div>


        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
