// Footer.js
import React from 'react';
import '../footer/footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p>© REMY Co., Ltd. 2024 All Rights Reserved</p>
    </div>
  );
}

export default Footer;
