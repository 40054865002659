import React from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import "../navbar/navbar.css"
import { Link } from 'react-router-dom';

const NavbarComponent = () => {

  const pageRedirect = () => {
    window.location.href = "/storypage";
  };

  return (
    <Container>
      <div className="web-view">
        <Navbar expand="lg" variant="light" style={{ backgroundColor: 'transparent' }}>
          <Container className="justify-content-between">
            <Navbar.Brand href="/#home">
              <Image src="images/logo.png" className="logo" alt="Logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav>
                <Nav.Link href="/#home">Home</Nav.Link>
                <Nav.Link href="/#accomplishments">Accomplishments</Nav.Link>
                <Nav.Link href="/#goals">Goals</Nav.Link>
                <Nav.Link href="/#road_map">Road Map</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <button className="custom-button" onClick={pageRedirect}>$REMY</button>
          </Container>
        </Navbar>
      </div>
      <div className="mobile-view">
        <br />
        <Navbar expand="lg" variant="dark" style={{ backgroundColor: 'transparent' }}>
          <Container className="position-relative">
            <Navbar.Brand href="/#home1" className="centered-logo">
              <Image src="images/logo.png" className="logo" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="order-lg-3 ml-auto no-focus no-border custom-toggler" />
            <Navbar.Collapse id="basic-navbar-nav" className="order-lg-2">
              <Nav className="ml-auto">
                <Nav.Link href="/#home1">Home</Nav.Link>
                <Nav.Link href="/#accomplishments1">Accomplishments</Nav.Link>
                <Nav.Link href="/#goals1">Goals</Nav.Link>
                <Nav.Link href="/#road_map1">Road Map</Nav.Link>
              </Nav>
              <button className="custom-button" onClick={pageRedirect}>$REMY</button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </Container>
  );
};

export default NavbarComponent;
