// Links.js
import React from 'react';
import '../links/links.css';
import Container from "react-bootstrap/Container";

const Links = () => {
  return (
    <Container>
      <div className="web-view">
        <div className="links-container">
          <div className="link-item">
            <a className='a_links' href='https://x.com/RemyTonCto'>
              <img src='images/1.png' alt="Twitter" className="link-icon" />
              <p className="link-label">Twitter</p>
            </a>
          </div>
          <a className='a_links' href='https://www.geckoterminal.com/ton/pools/EQABLSn_tv4jPv7UZDeLmrmHF4bt3m7RzVmLfgfmrEJeVshU'>
            <div className="link-item">
              <img src='images/2.png' alt="Geckoterminal" className="link-icon" />
              <p className="link-label">Geckoterminal</p>
            </div>
          </a>
          <a className='a_links' href='https://t.me/tgdogentry'>
            <div className="link-item">
              <img src='images/3.png' alt="Telegram" className="link-icon" />
              <p className="link-label">Telegram</p>
            </div>
          </a>
          <a className='a_links' href='https://dedust.io/swap/TON/EQBwJaB-51Efd1CraJskTAkx2NDZHf71ONWUlJl-KIGOzs4b'>
            <div className="link-item">
              <img src='images/4.png' alt="DeDust" className="link-icon" />
              <p className="link-label">DeDust</p>
            </div>
          </a>
          <a className='a_links' href='https://dexscreener.com/ton/eqablsn_tv4jpv7uzdelmrmhf4bt3m7rzvmlfgfmrejevshu'>
            <div className="link-item">
              <img src='images/5.png' alt="DexScreener" className="link-icon" />
              <p className="link-label">DexScreener</p>
            </div>
          </a>
          <a className='a_links' href='https://moontok.io/coins/telegrams-dog'>
            <div className="link-item">
              <img src='images/6.png' alt="Moontok" className="link-icon" />
              <p className="link-label">Moontok</p>
            </div>
          </a>
        </div>
      </div>
      <div className="mobile-view">
        <div className="links-container row">
          <div className='col-4'>
            <div className="link-item">
              <a className='a_links' href='https://x.com/RemyTonCto'>
                <img src='images/1.png' alt="Twitter" className="link-icon" />
                <p className="link-label">Twitter</p>
              </a>
            </div>
          </div>
          <div className='col-4'>
            <a className='a_links' href='https://www.geckoterminal.com/ton/pools/EQABLSn_tv4jPv7UZDeLmrmHF4bt3m7RzVmLfgfmrEJeVshU'>
              <div className="link-item">
                <img src='images/2.png' alt="Geckoterminal" className="link-icon" />
                <p className="link-label">Geckoterminal</p>
              </div>
            </a>
          </div>
          <div className='col-4'>
            <a className='a_links' href='https://t.me/tgdogentry'>
              <div className="link-item">
                <img src='images/3.png' alt="Telegram" className="link-icon" />
                <p className="link-label">Telegram</p>
              </div>
            </a>
          </div>
        </div>
        <div className="links-container row">
          <div className='col-4'>
            <a className='a_links' href='https://dedust.io/swap/TON/EQBwJaB-51Efd1CraJskTAkx2NDZHf71ONWUlJl-KIGOzs4b'>
              <div className="link-item">
                <img src='images/4.png' alt="DeDust" className="link-icon" />
                <p className="link-label">DeDust</p>
              </div>
            </a>
          </div>
          <div className='col-4'>
            <a className='a_links' href='https://dexscreener.com/ton/eqablsn_tv4jpv7uzdelmrmhf4bt3m7rzvmlfgfmrejevshu'>
              <div className="link-item">
                <img src='images/5.png' alt="DexScreener" className="link-icon" />
                <p className="link-label">DexScreener</p>
              </div>
            </a>
          </div>
          <div className='col-4'>
            <a className='a_links' href='https://moontok.io/coins/telegrams-dog'>
              <div className="link-item">
                <img src='images/6.png' alt="Moontok" className="link-icon" />
                <p className="link-label">Moontok</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Links;
