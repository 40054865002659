// Goals.js
import React from 'react';
import '../goals/goals.css';
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const Goals = () => {
  return (
    <Container>
      <br/>
      <div id="home" className="web-view">
        <div id='goals'>
          <div className="row">
            <div className="col-4">
            </div>
            <div className="col-5">
              <div className="goals-card">
                <div className="row">
                  <div className="col-4">
                    <span className="goals-title">GOALS</span>
                  </div>
                  <div className="col-8">
                    <p className="goals-text">To Make $REMY One Of The Successful<br />Projects On The Ton Network</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
            </div>
          </div>
        </div>
      </div>
      <div id="home" className="mobile-view">
        <div id='goals1'>
              <div className="goals-card">
                <div className="row">
                  <div className="col-4">
                    <span className="goals-title">GOALS</span>
                  </div>
                  <div className="col-8">
                    <p className="goals-text">To Make $REMY One Of The Successful<br />Projects On The Ton Network</p>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Goals;
