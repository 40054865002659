import React from "react";
import "../banner/banner.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";

const Banner = () => {
  
  const pageRedirect = () => {
    window.location.href = "https://t.me/tgdogentry";
  };

  return (
    <Container>
      <div id="home" className="web-view">
        <div className="row">
          <div className="col-6">
            <br /><br />
            <p className="text-small top-text">Telegrams favorite Jack Russell Terrier!!! Now CTO!!!</p>
            <p className="text-large">WELCOME TO <br /><span className="text-blue">$REMY</span> ON<br />$TON</p>
            <br /><br />
            <p className="description">
              🐕 $REMY is not just a community-owned token it’s a symbol<br /> of strength, unity, and community 💪empowerment.<br /><br />

              💰 With 100% of the liquidity owned by the community, putting true<br /> power and freedom into the hands of the investors!!!<br />
              <br />
              🚇 Join us on our revolutionary journey!!!  $REMY
            </p>
            <br /><br />
            <p>NOTHING WILL STOP US.</p>
            <button className="custom-button" onClick={pageRedirect} >Join Us Now </button>
          </div>
          <div className="col-6">
            <p className="top-text-right">Did you Know that telegram needed a Dog???...<br /><span className="start-left">And that Dog is $REMY&nbsp;&nbsp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span></p>
            <div className="circle" />
            <img src="/images/combined.png" alt="combined" className="dog-image" />
          </div>
        </div>
        <div className="banner-text">
          <div className="row">
            <div className="col-12">

            </div>
          </div>
        </div>
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-8"></div>
            <div className="col-md-4 text-right remi">

            </div>
          </div>
        </div>
        <div className="detail-container">
          <div className="detail-item">LP BURNED</div>
          <div className="detail-item">MINT REVOKED</div>
          <div className="detail-item">TAX 0/0%</div>
          <div className="detail-item2">Contract: EQBwJaB-51Efd1CraJskTAkx2NDZHf71ONWUlJI-KIGOzs4b</div>
        </div><br />
      </div>
      <div id="home1" className="mobile-view">
        <div className="row">
          <div className="col-12">
            <br /><br />
            <p className="text-small top-text center-text">Telegrams favorite Jack Russell Terrier!!! Now CTO!!!</p>
            <p className="text-large center-text">WELCOME TO <br /><span className="text-blue">$REMY</span> ON<br />$TON</p>
            <br /><br />
            <img src="/images/combined.png" alt="combined" className="dog-image" />
            <p className="description center-text">
              🐕 $REMY is not just a community-owned token it’s a symbol<br /> of strength, unity, and community 💪empowerment.<br /><br />

              💰 With 100% of the liquidity owned by the community, putting true<br /> power and freedom into the hands of the investors!!!<br />
              <br />
              🚇 Join us on our revolutionary journey!!!  $REMY
            </p>
            <br /><br />
            <p className="center-text">NOTHING WILL STOP US.</p>
            <div className="center-button">
              <a className="custom-button" href="https://t.me/tgdogentry">Join Us Now </a>
            </div>
          </div>
          <div className="circle" />
          <br /><br />
        </div>
        <div className="banner-text">
          <div className="row">
            <div className="col-12">
            <br /><br />
            </div>
          </div>
        </div>
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-8"></div>
            <div className="col-md-4 text-right remi">

            </div>
          </div>
        </div>
        <div className="detail-container">
          <div className="detail-item center-text">LP BURNED</div>
          <div className="detail-item center-text">MINT REVOKED</div>
          <div className="detail-item center-text">TAX 0/0%</div>
        </div>
        <div className="detail-container center-button">
          <div className="detail-item2 center-text">Contract: EQBwJaB-51Efd1CraJskTAkx2NDZHf71ONWUlJI-KIGOzs4b</div>
        </div>
        <br />
      </div>
    </Container>
  );
};

export default Banner;
