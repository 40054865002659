import React from 'react';
import './roadmap.css';
import Container from "react-bootstrap/Container";

const Roadmap = () => {
    return (
        <Container className="roadmap-container">
            <div className="web-view">
                <div id="road_map">
                    <h1 className='topic'>ROADMAP</h1>
                    <div className="roadmap-row-container" key="0">
                        <div className="roadmap-row">
                            <div className="col-4 roadmap-item" key="1">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">01</div>
                                    <div className="roadmap-line line-0"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">SOCIAL MEDIA</span><br />Continued Presence On<br />Social Media<br />Telegram<br />Twitter/X</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item" key="1">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">02</div>
                                    <div className={`roadmap-line line-0`}></div>
                                </div>
                                <div className="roadmap-content">
                                    <p className="roadmap-content-description-02">AMA WITH <span className='highlight'>$REMY</span> COMMUNITY</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item" key="2">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">03</div>
                                    <div className="roadmap-line line-0"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">$REMY NFT SERIES</span><br />Limited Edition $REMY NFTs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap-row-container">
                        <div className="roadmap-row">
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">04</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">MARKETING</span><br />Ton Trending Callers<br />Twitter/X Posts</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">05</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">FUTURE LISTINGS</span><br />Reach out to exchanges for listing</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">06</div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">AIR DROP</span><br />Airdrop $REMY To Top<br />Holders Of Larger Successful Projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap-row-container">
                        <div className="roadmap-row">
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">07</div>
                                    <div className="roadmap-line line-2"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">TRADING PAIRS</span><br />Pair $REMY with other popular TON projects</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step special-step">08</div>
                                    <div className="roadmap-line line-2"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">COINGECKO &amp; CMC</span><br />List $REMY with CoinGecko &amp; CMC</p>
                                </div>
                            </div>
                            <div className="col-4 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step special-step">09</div>
                                </div>
                                <div className="roadmap-content">
                                    <p className="roadmap-content-description-02"><span className='highlight'>$REMY</span> TOKEN BURN</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-view">
                <div id="road_map1">
                    <h1 className='topic'>ROADMAP</h1>
                    <div className="roadmap-row-container" key="0">
                        <div className="roadmap-row">
                            <div className="col-8 roadmap-item" key="1">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">01</div>
                                    <div className="roadmap-line line-0"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">SOCIAL MEDIA</span><br />Continued Presence On<br />Social Media<br />Telegram<br />Twitter/X</p>
                                </div>
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                            <div className='col-4'></div>
                            <div className="col-8 roadmap-item" key="1">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">02</div>
                                    <div className={`roadmap-line line-1`}></div>
                                </div>
                                <div className="roadmap-content">
                                    <p className="roadmap-content-description-02">AMA WITH <span className='highlight'>$REMY</span> COMMUNITY</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                            <div className="col-8 roadmap-item" key="2">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">03</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">$REMY NFT SERIES</span><br />Limited Edition $REMY NFTs</p>
                                </div>
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                        <div className='col-4'></div>
                            <div className="col-8 roadmap-item" key="2">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">04</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">MARKETING</span><br />Ton Trending Callers<br />Twitter/X Posts</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                            <div className="col-8 roadmap-item" key="2">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">05</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">FUTURE LISTINGS</span><br />Reach out to exchanges for listing</p>
                                </div>
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                        <div className='col-4'></div>
                            <div className="col-8 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step">06</div>
                                    <div className="roadmap-line line-1"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">AIR DROP</span><br />Airdrop $REMY To Top<br />Holders Of Larger Successful Projects</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                            <div className="col-8 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step ">07</div>
                                    <div className="roadmap-line line-2"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">TRADING PAIRS</span><br />Pair $REMY with other popular TON projects</p>
                                </div>
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                        <div className='col-4'></div>
                            <div className="col-8 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step special-step">08</div>
                                    <div className="roadmap-line"></div>
                                </div>
                                <div className="roadmap-content">
                                    <p><span className="roadmap-content-head">COINGECKO &amp; CMC</span><br />List $REMY with CoinGecko &amp; CMC</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="roadmap-row">
                            <div className="col-8 roadmap-item">
                                <div className="roadmap-step-container">
                                    <div className="roadmap-step special-step">09</div>
                                </div>
                                <div className="roadmap-content">
                                    <p className="roadmap-content-description-02"><span className='highlight'>$REMY</span> TOKEN BURN</p>
                                </div>
                            </div>
                            <div className='col-4'></div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Roadmap;
