import React from "react";
// Ensure this path is correct
import "../accomplishment/accomplishment.css"
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const statsData = [
  { label: "STRONG", label2: "COMMUNITY" },
  { label: "$REMY ", label2: "IS VERIFIED ON BOTH DEDUST.IO AND TONKEEPER" },
  { label: "LISTED ON ", label2: "MOONTOK" },
  { label: "UPDATED ", label2: "GECKOTERMINAL" },
  { label: "EARNED OFFICIAL  ", label2: "BLUE TWITTER/X BADGE" },
];

const accomplishment = () => {
  return (
    <Container>
      <div className="web-view">
        <div className="accomplishment-div">
          <div id="accomplishments">
            <br /><br />
            <div className="container text-center">
              <header>
                <h1 className="agency-header" style={{ lineHeight: 0.6, color: "white" }}>
                  Accomplishments
                </h1>
              </header>
              <br />

              <section className="stats text-justify my-5">
                <Row>
                  <Col xs="auto" >
                    <p>STRONG <span className="text-blue">COMMUNITY</span></p>
                  </Col>
                  <Col xs="auto" className="vertical-divider" />
                  <Col xs="auto" >
                    <p><span className="text-blue">$REMY </span>IS VERIFIED ON BOTH<br />DEDUST.IO AND TONKEEPER</p>
                  </Col>
                  <Col xs="auto" className="vertical-divider" />
                  <Col xs="auto" >
                    <p>LISTED ON <span className="text-blue">MOONTOK</span></p>
                  </Col>
                  <Col xs="auto" className="vertical-divider" />
                  <Col xs="auto" >
                    <p>UPDATED <span className="text-blue">GECKOTERMINAL</span></p>
                  </Col>
                  <Col xs="auto" className="vertical-divider" />
                  <Col xs="auto" >
                    <p><span className="text-blue">EARNED OFFICIAL </span>BLUE <br />TWITTER/X BADGE</p>
                  </Col>
                </Row>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-view">
        <div className="accomplishment-div">
          <div id="accomplishments1">
            <br /><br />
            <div className="container text-center">
              <header>
                <h1 className="agency-header" style={{ lineHeight: 0.6, color: "white" }}>
                  Accomplishments
                </h1>
              </header>
              <br />

              <section className="stats text-justify my-5">
                <Row>
                  <div className="center-all" >
                    <p className="center-text">STRONG <span className="text-blue">COMMUNITY</span></p>
                  </div>
                </Row>
                <Row>
                  <br />
                  <br />
                  <div className="center-all" >
                    <Row xs="auto" className="horizontal-divider" />
                  </div>
                  <br />
                  <br />
                </Row>
                <Row>
                  <div className="center-all" >
                    <p className="center-text"><span className="text-blue">$REMY </span>IS VERIFIED ON BOTH<br />DEDUST.IO AND TONKEEPER</p>
                  </div>
                </Row>
                <Row>
                  <br />
                  <br />
                  <div className="center-all" >
                    <Row xs="auto" className="horizontal-divider" />
                  </div>
                  <br />
                  <br />
                </Row>
                <Row>
                  <div className="center-all" >
                    <p className="center-text">LISTED ON <span className="text-blue">MOONTOK</span></p>
                  </div>
                </Row>
                <Row>
                  <br />
                  <br />
                  <div className="center-all" >
                    <Row xs="auto" className="horizontal-divider" />
                  </div>
                  <br />
                </Row>
                <Row>
                  <div className="center-all" >
                    <p>UPDATED <span className="text-blue">GECKOTERMINAL</span></p>
                  </div>
                </Row>
                <Row>
                  <br />
                  <br />
                  <div className="center-all" >
                    <Row xs="auto" className="horizontal-divider" />
                  </div>
                  <br />
                </Row>
                <Row>
                  <div className="center-all" >
                    <p className="center-text"><span className="text-blue">EARNED OFFICIAL </span>BLUE <br />TWITTER/X BADGE</p>
                  </div>
                </Row>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default accomplishment;
